<template>
  <div id="compare-view">
    <main id="maincontent" class="page-main">
      <a id="contentarea" tabindex="-1"></a>
      <div class="columns col1-layout">
        <div class="container ">
          <p v-if="imageshow">
            <span class="d-flex justify-content-center"
              ><img
                class="img-fluid text-center"
                src="@/assets/site/images/comparison.png"
                alt=""
            /></span>
          </p>
          <table class="table-bordered" v-else>
            <thead>
              <tr>
                <th class="compare-heading d-flex align-items-self pt-5">
                  Product Name
                </th>

                <th class="price-info" v-for="cproduct in cproducts">
                  {{ cproduct.compair_product.productname }}
                  <b-button
                    size="sm"
                    variant="outline-danger"
                    @click="deleteCompairList(cproduct)"
                    class="fa fa-trash gift-icon px-3 pull-right"
                    v-b-popover.hover.top="'Delete'"
                  ></b-button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="compare-heading">Image</td>
                <td
                  v-for="cproduct in cproducts"
                  colspan="1"
                  class="text-center"
                >
                  <a href="#"
                    ><img
                      :src="cproduct.compair_product.imageurl"
                      class="compare-image-photo img-fluid"
                      alt="Product"
                      @click="ViewSingleProduct(cproduct.compair_product)"
                    />
                  </a>
                </td>
              </tr>
              <tr class="compare-row">
                <td class="compare-heading">Price</td>
                <td v-for="cproduct in cproducts" style="width: 25%;">
                  {{ cproduct.compair_product.price["@currency"] }}
                  {{ cproduct.compair_product.price["#text"] }}
                </td>
              </tr>
              <tr class="compare-row">
                <td class="compare-heading">Description</td>
                <td v-for="cproduct in cproducts">
                  {{ cproduct.compair_product.description.long }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>

    <!-- End Footer -->
  </div>
</template>
<script>
export default {
  name: "CompareView",
  metaInfo: {
    title: "Compare",
    meta: [
      {
        name: "description",
        content:
          "Compare your favorite gifts effortlessly with our interactive comparison feature! View detailed product information, including images, prices, and descriptions, to help you make the ultimate gift choice. Simplify your shopping experience and find the perfect gift today!",
      },
      {
        name: "keywords",
        content:
          "gift comparison, compare products, product details, price comparison, online shopping, gift options, product reviews, image comparison, gift selection, shopping tools, gift features, customer favorites, product descriptions, interactive comparison, unique gifts",
      },
    ],
  },
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      cproducts: [],
      imageshow: false,
    };
  },
  created() {
    this.getCompairProducts();
  },
  methods: {
    getCompairProducts() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/compare/getCompairProducts";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.cproducts = data.compairlist;
          if (this.cproducts.length == 0) {
            this.imageshow = true;
          }
        });
    },
    deleteCompairList(cproduct) {
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/compare/deleteProduct";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
          product_id: cproduct.product_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status === true) {
            this.loader = false;
            this.getCompairProducts();
            this.$swal.fire("Deleted", data.message, "success");
          } else {
            this.loader = false;
          }
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    ViewSingleProduct(cproduct) {
      this.$store.state.pet_id = null;
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: { singleproduct: cproduct },
      });
    },
  },
};
</script>
